<template>
  <div class="section">
    <div>
      <form>
        <div class="group-container">
          <md-card-header-text>Scraping status:  </md-card-header-text>
          <p class="text" v-if="isFetchingStatus">
            <b>Fetching...</b>
          </p>
          <p class="text" v-if="!isFetchingStatus">
            <b>{{ scrapingStatus }}</b>
          </p>
        </div>
        <div class="group-container">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="file"
              ref="file"
              multiple
              @change="updateList()"
            />
            <label class="custom-file-label" for="file">
              <i class="fa-solid fa-upload"></i>
              Upload Multi URLs CSV</label
            >
          </div>
          <ul id="fileList" class="file-list"></ul>
        </div>
        <div>
          <button class="btn btn-danger group-section-btn" @click="interruptScraping()" v-if="typeof scrapingStatus === 'string' && !scrapingStatus.startsWith('Inactive')">
            Interrupt scraping
          </button>
          <button class="btn btn-primary group-section-btn" @click.prevent="showResult()" v-if="!isLoading">
            Run Scraping
          </button>
        </div>
        <p class="text" v-if="is_searching">
          Searching is processing in background...
        </p>
      </form>
    </div>
    <div>
      <div class="group-container">
        <md-card-header-text>Total number of Linkedin URLs to export:
          <p class="text" v-if="!isFetchingTotalStatus"><b>...</b></p>
          <p class="text" v-if="isFetchingTotalStatus">
            <b>{{ totalNumberOfProfiles }}</b>
          </p>
        </md-card-header-text>
      </div>
          <button class="btn btn-danger group-section-btn" @click="delete_all()">
            Delete All URLs
          </button>
          <button class="btn btn-outline-primary group-section-btn" @click="exportAsCsv()">
            Export as CSV
          </button>
      </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import NotificationService from "../services/notifications.service";

export default {
  name: "HomePage",
  data() {
    return {
      csv_uploading: false,
      csv_file: false,
      search_profile: null,
      search_url: "",
      links: [],
      backup_links: [],
      user_id: null,
      is_searching: false,
      isOpen: false,
      selectedName: "Select a profile",
      profiles: [],
      scrapingStatus: null,
      urlsLeft: -1,
      isFetchingStatus: false,
      totalNumberOfProfiles: null,
      isFetchingTotalStatus: false,
      isFetchingProfiles: false,
      selectedProfileId: -1,
      intervalId: null
    };
  },
  computed: {
    isLoading() {
      return this.csv_uploading;
    },
  },
  async mounted() {
    await this.fetchStatus();
  },
  async created() {
    try {
      const response = await ApiService.get("/me");
      this.user_id = response.data.id;
      await this.fetchNumberOfLinkedinProfiles();
    } catch (error) {
      NotificationService.error("Failed to fetch user details");
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    truncateString(str, maxLength) {
      if (str.length <= maxLength) return str;
      return '...' + str.slice(-maxLength);
    },
    async delete_all() {
      try {
        await ApiService.delete(`/delete-all-links/${this.user_id}`);
        NotificationService.success("The urls were deleted");
        this.links = [];
        this.backup_links = [];
      } catch (err) {
        NotificationService.error("Failed to delete all links");
      }
    },
    async interruptScraping() {
      try {
        await ApiService.get(`/api/interrupt-scraping`);
        NotificationService.success("The scraping has been interrupted");
        this.links = [];
        this.backup_links = [];
      } catch (err) {
        NotificationService.error("Interruption has failed");
      }
    },

    updateList() {
      var input = document.getElementById("file");
      this.csv_file = this.$refs.file.files[0];
      var output = document.getElementById("fileList");
      var children = "";
      for (var i = 0; i < input.files.length; ++i) {
        children +=
          "<li>" +
          input.files.item(i).name +
          '<span id="hide" class="remove-list" onclick="return this.parentNode.remove()">X</span>' +
          "</li>";
      }
      output.innerHTML = children;
    },

    async exportAsCsv() {
      try {
        const response = await ApiService.get(`/export-data/${this.user_id}`, {
          responseType: "blob"
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (err) {
        NotificationService.error("Failed to export data");
      }
    },

    searchProfileFromDB() {
      if (!this.search_profile) {
        this.links = [...this.backup_links];
        this.backup_links = [];
        return;
      }
      this.links = this.backup_links.filter(link =>
        link.includes(this.search_profile)
      );
    },

    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        NotificationService.success("Text copied to clipboard");
      }).catch(() => {
        NotificationService.error("Failed to copy text");
      });
    },

    removeItem(indexToRemove) {
      this.links.splice(indexToRemove, 1);
    },

    async fetchStatus() {
        this.isFetchingStatus = true;
        try {
            const response = await ApiService.get('/api/scraping-status');
            this.scrapingStatus = response.data.status;
            this.urlsLeft = response.data.urls_left;
        } catch (err) {
            NotificationService.error("Failed to fetch scraping status");
        } finally {
            this.isFetchingStatus = false;
        }
    },

    async fetchNumberOfLinkedinProfiles() {
      try {
        this.isFetchingTotalStatus = false;
        const res = await ApiService.get("/api/total-profile-links");
        if ("total_num" in res.data) {
          this.totalNumberOfProfiles = res.data["total_num"];
        }
      } catch (error) {
        NotificationService.error("Failed to fetch profile links");
      } finally {
        this.isFetchingTotalStatus = true;
      }
    },

    async showResult() {
      if (!this.csv_file) {
        NotificationService.error("Please add the csv file.");
      } else {
        NotificationService.info("csv file scraping in progress...");
        try {
          this.csv_uploading = true;
          const formData = new FormData();
          formData.append("file", this.csv_file);
          const headers = { headers: { "Content-Type": "multipart/form-data" } };
          const response = await ApiService.post("/api/create-with-csv", formData, headers);
          this.csv_uploading = false;
          // this.csv_file = false;
          NotificationService.info(response.data.message);
          await this.fetchStatus();
        } catch (err) {
          NotificationService.error("Can't run scraper with the csv file");
          this.csv_uploading = false;
          // this.csv_file = false;
        }
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
  max-width: 1250px;
  width: 100%;
  margin: 8rem auto;
}

/* form starting stylings ------------------------------- */

.group-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: auto 20px;
}
.group-container {
  margin-bottom: 0;
}
.group {
  position: relative;
  margin: 45px 55px 45px 0;
}
.group input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 400px;
  border: none;
  border-bottom: 1px solid #757575;
}
.group input:focus {
  outline: none;
}

.group-1 input {
  width: 350px;
}

/* LABEL ======================================= */
.group label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.group input:focus ~ label,
.group input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #006d77;
}

/* BOTTOM BARS ================================= */
.group .bar {
  position: relative;
  display: block;
  width: 400px;
}

.group-1 .bar {
  width: 350px;
}
.group .bar:before,
.group .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #006d77;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.group .bar:before {
  left: 50%;
}
.group .bar:after {
  right: 50%;
}

/* active state */
.group input:focus ~ .bar:before,
.group input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.group input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #006d77;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #006d77;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #006d77;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.group-section-btn {
  padding: 15px 15px 15px 15px;
  border-radius: 8px;
  font-size: 17px;
  margin: 0 15px 15px 15px;
}

.table-actions-btn button:nth-child(1) {
  border: 1px solid #254117;
}

.table-actions-btn button:nth-child(1):active {
  opacity: 0.6;
}

.table-actions-btn button:nth-child(1) i {
  color: #254117;
  font-size: 16px;
}

.table-actions-btn button:nth-child(2) {
  border: 1px solid #ff0000;
}

.table-actions-btn button:nth-child(2):active {
  opacity: 0.6;
}

.table-actions-btn button:nth-child(2) i {
  color: #ff0000;
  font-size: 16px;
}

.show-entries-btn {
  display: flex;
  justify-content: start;
  align-items: start;
  color: #000000;
  font-weight: 500;
  margin: 0rem 2rem;
}
.show-entries-btn p {
  margin: 5px 5px 0px 5px;
}

.show-entries-select-btn {
  width: 100px;
  height: 35px;
}

.table__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.user-add-btn {
  margin: 1rem 1rem;
}
.user-add-btn button {
  border: 2px solid #03a9f4;
  color: #03a9f4;
  background: #ffffff;
  padding: 5px 20px;
  border-radius: 8px;
}

.user-add-btn button:hover {
  background: #03a9f4;
  color: #ffffff;
}

.custom-file {
  position: relative;
  overflow: hidden;
  width: auto;
  display: inline-block;
  padding: 10px;
}
.custom-file-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
}
.custom-file i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  cursor: pointer;
  font-size: 25px;
  color: #254117;
}
ul.file-list {
  font-family: arial;
  list-style: none;
  padding: 0;
}
ul.file-list li {
  border-bottom: 1px solid #ddd;
  padding: 5px;
}
.remove-list {
  cursor: pointer;
  margin-left: 10px;
}

.custom-file-label {
  font-size: 20px;
  border: 1px dashed #242424;
  padding: 15px 15px 15px 15px;
}
</style>
